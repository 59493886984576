<template>
  <div>
    <h1 v-if="title" class="text-h4 secondary-prosk mb-4">
      {{ title }}
    </h1>

    <v-col cols="10" md="8" offset-md="2" offset="1">
      <video class="w-100" controls>
        <source :src="videoUrl" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </v-col>
  </div>
</template>

<script>
export default {
  name: 'VideoBanner',
  props: ['videoUrl', 'title']
};
</script>
