<template>
  <v-main class="pt-4">
    <v-row v-if="loading" class="mx-16">
      <v-col v-for="index in 6" :key="index" cols="2">
        <v-skeleton-loader
            v-bind="skeletonAttrs"
            type="list-item-avatar, list-item-three-line, divider, list-item"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="mx-md-16" v-else>
      <v-col :class="$vuetify.breakpoint.mobile ? 'text-center' : ''">
        <h3 class="my-3 secondary-prosk">
          {{ $t("testimonials") }}
        </h3>
      </v-col>
      <v-col cols="12" class="mx-0 py-0">
        <carousel :scrollPerPage="false" :perPage="1" :perPageCustom="[[768, 4]]">
          <slide v-for="(item, n) in testimonials" :key="n" class="w-100 px-2 py-2" paginationActiveColor="#3F44A6">
            <v-card
                class="mx-auto round"
                width="100%"
                height="auto"
                min-height="300"
            >
              <v-card-title>
                <v-row>
                  <v-col cols="3">
                    <v-avatar width="3rem" height="3rem">
                      <img
                          v-if="item.jobs && item.jobs.client_user.profile_img"
                          :src="item.jobs.client_user.profile_img"
                          class="img-fluid rounded-circle border border-dark avatar-40"
                          alt="user"
                      />
                      <img
                          v-else
                          src="@/assets/images/page-img/avatar.png"
                          class="img-fluid rounded-circle avatar-40"
                          alt="user"
                      />
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <v-row>
                      <v-col cols="12">
                        <p class="m-0 font-weight-bold font-size-16 text-break pointer-cursor secondary-prosk" v-if="item.jobs"
                              @click="goToUserProfile(item.prosker_user)">
                          {{ item.prosker_user.first_name }} {{ item.prosker_user.last_name }}
                        </p>
                        <p class="description">{{ item.jobs.client_user.category_name[0] }} </p>
                        <p v-show="!item.jobs.client_user.category_name[0]" class="mb-12" ></p>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-rating
                      background-color="#F2D022"
                      half-increments
                      color="#F2D022"
                      hover
                      readonly
                      dense
                      v-model="item.rating"
                      style="margin-left: 12px; margin-top: -32px;"
                  ></v-rating>
                </v-row>
              </v-card-title>
              <v-card-text class="pb-10 pt-5">
                <p class="ml-3 secondary-prosk font-weight-bold text-capitalize">{{ item.title }}</p>
                <p class="ml-3 secondary-prosk">{{ item.content }}</p>
                <p class="ml-3 green-prosk">
                  {{ item.jobs.client_user.first_name }} {{ item.jobs.client_user.last_name }}
                </p>
              </v-card-text>
              <v-card-subtitle class="bottom-0 position-absolute w-100">
                <small class="float-end secondary-prosk">{{ getDateFormat(item.created_at) }} {{ $t("daysAgo") }}</small>
              </v-card-subtitle>
            </v-card>
          </slide>
        </carousel>
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
import axios from 'axios';
import moment from 'moment/moment';
import { mapGetters } from 'vuex';
import { Carousel, Slide } from 'vue-carousel';
import goToUserProfile from '@/components/socialvue/mixins/goToUserProfile';
export default {
  name: 'Testimonials',
  mixins: [goToUserProfile],
  components: {
    Carousel,
    Slide
  },
  data () {
    return {
      skeletonAttrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2
      },
      userSearchedObject: {},
      loading: false,
      testimonials: []
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  created () {
    this.getReviews();
  },
  methods: {
    getDateFormat (date) {
      const serverDate = new Date(date);
      const myDate = new Date();
      const difference = myDate - serverDate;
      return moment(moment.utc(difference)).format('D');
    },
    getReviews () {
      this.loading = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/reviews?per_page=8&sortBy=created_at,desc`;
      axios
        .get(url)
        .then((response) => {
          this.testimonials = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.showError(error);
          this.loading = false;
        });
    }
  }
};
</script>
<style scoped>
  .round {
    border-radius: 12px;
  }

  .description {
    font-size: 1.1rem;
    color: #59D79D;
    margin-top: -8px;
  }
</style>
